@import "../../assets/scss/Variables.scss";

.order-progress {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  //border-left: 1px solid rgb(232, 232, 232);
  position: absolute;
  top: 0;
  right: 100%;
  visibility: hidden;
  z-index: 99999;

  .order-progress--header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 70px;
    border-bottom: 1px solid rgb(222, 222, 222);
    position: relative;
    width: 100%;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    letter-spacing: 1px;

    .order-progress--header--return {
      position: absolute;
      left: 10px;
      padding: 10px;
      cursor: pointer;
    }

    .order-progress--header--refresh {
      position: absolute;
      right: 10px;
    }
  }

  .order-progress--post-header {
    top: 70px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 990;
    background: #d005059c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
  }

  .order-progress--body {
    max-height: calc(100vh - 70px);
    overflow: hidden;

    .order-progress--body--infos {
      &.fixed {
        position: fixed;

        border-top-radius: 20px;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        overflow: hidden;
        height: 100%;
        max-height: 180px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        z-index: 999;
      }

      &.finished {
        display: none !important;
      }
    }

    .order-progress--body--top {
      width: 100%;
      max-width: 100vw;
      display: flex;
      padding: 10px 20px;
      align-items: center;
      gap: 20px;
      justify-content: space-between;
      border-bottom: 1px solid $background-color-readonly-group;

      label {
        margin: 0;
      }

      .order-progress--body--top--left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .order-progress--body--top--right {
        button {
          padding: 0 10px;
        }
      }
    }

    .order-progress--body--center {
      width: 100%;
      display: flex;
      padding: 20px 10px;

      .vertical-timeline {
        padding: 0;
        width: 100%;

        .vertical-timeline-element {
          margin: 0;

          label {
            margin: 0;
            font-size: 12px;
          }

          .vertical-timeline-element-content {
            padding: 0;
            box-shadow: none;
            margin-left: 40px;
            display: flex;
            align-items: flex-start;

            .vertical-timeline-element-date {
              padding: 0;
              opacity: 0.7;
              font-weight: 600;
            }

            &::after {
              content: none;
            }
          }

          .vertical-timeline-element-icon {
            width: 20px;
            height: 20px;
            box-shadow: none;
            display: flex;
            justify-content: center;

            svg {
              border-radius: 50%;
              border: 4px solid #d00505;
            }
          }
        }

        &::before {
          left: 14px;
          width: 1px;
          background: #4f5256;
        }
      }
    }

    .order-progress--body--bottom {
      position: relative;

      button {
        width: 100%;
      }

      .order-progress--body--bottom-fake-map {
        height: 100%;
        aspect-ratio: 558 / 689;
        user-select: none;
      }

      .order-progress--body--bottom-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        max-height: calc(100vh - 70px - 180px);

        &.minus {
          background-color: rgba(255, 255, 255, 0.15);
        }

        &.finished {
          max-height: calc(100vh - 70px);
          display: flex;
        }

        .order-progress--body--bottom-overlay--finished {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 20px;

          i {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #50a773;
            height: 60px;
            width: 60px;
            border-radius: 50%;
          }

          .order-progress--body--bottom-overlay--finished-title {
            max-width: 100%;
            margin-top: 20px;
            font-weight: 700;
            font-size: 28px;
            color: #50a773;
          }

          .order-progress--body--bottom-overlay--finished--infos {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 40px;
            width: 100%;

            .order-progress--body--bottom-overlay--finished--infos--item {
              text-align: center;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              margin-top: 40px;

              .order-progress--body--bottom-overlay--finished--infos--item-title {
                font-size: 14px;
                width: 100%;
                white-space: nowrap;
                margin: 0;
                padding: 0;
                font-weight: 300;
                color: #717171;
              }

              .order-progress--body--bottom-overlay--finished--infos--item-subtitle {
                width: 100%;
                margin: 0;
                padding: 0;
                font-size: 32px;
                font-weight: 700;
                color: #3e3e3e;
              }
            }
          }
        }

        h2 {
          font-size: 18px;
          font-weight: 700;
          color: #545454;
          max-width: 70%;
        }
      }

      .order-progress--body--bottom-company-marker,
      .order-progress--body--bottom-home-marker {
        img {
          width: 25px;
          position: relative;
        }
      }
    }
  }

  .order-progress--body--bottom--button {
    width: 100%;
    border: none;
    padding: 10px 20px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 12px;
    background-color: #25d366;
    color: #fff;
    transition: .2s all ease;

    &:hover {
      filter: opacity(0.9);
    }
  }
}

.order-progress-active {
  animation: moveFromRight 0.3s;
  visibility: visible;
  right: 0;
}

.mapboxgl-marker:has(.order-progress--body--bottom-company-marker) {
  transition: 2s all ease !important;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes moveFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes moveToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
